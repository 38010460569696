export enum AirlineDocumentType {
  ElectronicMiscellaneousDocumentAssociated = 'ELECTRONIC_MISCELLANEOUS_DOCUMENT_ASSOCIATED',
  ElectronicMiscellaneousDocumentStandalone = 'ELECTRONIC_MISCELLANEOUS_DOCUMENT_STANDALONE',
  ElectronicTicket = 'ELECTRONIC_TICKET',
}

export enum BedType {
  AirMattress = 'AirMattress',
  BunkBed = 'BunkBed',
  Cot = 'Cot',
  Couch = 'Couch',
  Crib = 'Crib',
  Double = 'Double',
  FloorMattress = 'FloorMattress',
  Futon = 'Futon',
  Hammock = 'Hammock',
  King = 'King',
  Queen = 'Queen',
  Single = 'Single',
  SmallDouble = 'SmallDouble',
  SofaBed = 'SofaBed',
  SuperKing = 'SuperKing',
  ToddlerBed = 'ToddlerBed',
  WaterBed = 'WaterBed',
}

export enum BookingWindowPriceResolution {
  Day = 'DAY',
  Hour = 'HOUR',
}

export enum Border {
  Air = 'AIR',
  Land = 'LAND',
  Sea = 'SEA',
}

export enum CancelPenaltyType {
  Amount = 'AMOUNT',
  Nights = 'NIGHTS',
  None = 'NONE',
  Percentage = 'PERCENTAGE',
}

export enum ChargeCategory {
  Adjustment = 'ADJUSTMENT',
  BaseRate = 'BASE_RATE',
  Compensation = 'COMPENSATION',
  ExtraPersonFee = 'EXTRA_PERSON_FEE',
  MandatoryFee = 'MANDATORY_FEE',
  MandatoryTax = 'MANDATORY_TAX',
  Other = 'OTHER',
  PropertyFee = 'PROPERTY_FEE',
  RecoveryChargesAndFees = 'RECOVERY_CHARGES_AND_FEES',
  ResortFee = 'RESORT_FEE',
  SalesTax = 'SALES_TAX',
  TaxAndServiceFee = 'TAX_AND_SERVICE_FEE',
  TravelerServiceFee = 'TRAVELER_SERVICE_FEE',
}

/** Properties by which Contact connections can be ordered. */
export enum ContactOrderField {
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
  FullName = 'FULL_NAME',
  UpdatedAt = 'UPDATED_AT',
  Verified = 'VERIFIED',
}

/** Properties by which Country connections can be ordered. */
export enum CountryOrderField {
  Continent = 'CONTINENT',
  ContinentCode = 'CONTINENT_CODE',
  Developed = 'DEVELOPED',
  Name = 'NAME',
  SubContinent = 'SUB_CONTINENT',
}

export enum DirectiveState {
  Current = 'CURRENT',
  Expired = 'EXPIRED',
  Upcoming = 'UPCOMING',
}

export enum DuffelPlaceType {
  Airport = 'Airport',
  City = 'City',
}

export enum DurationExtensionMeasures {
  NoTest = 'NO_TEST',
  Symptoms = 'SYMPTOMS',
  TestPositive = 'TEST_POSITIVE',
}

export enum DurationReductionMeasures {
  Asymptomatic = 'ASYMPTOMATIC',
  MedicalCertificate = 'MEDICAL_CERTIFICATE',
  Test = 'TEST',
  TestNegative = 'TEST_NEGATIVE',
}

/** Properties by which Equipment connections can be ordered. */
export enum EquipmentOrderField {
  Iata = 'IATA',
  Manufacturer = 'MANUFACTURER',
  Type = 'TYPE',
}

/** ExpediaRateOption is enum for the field expedia_rate_option */
export enum ExpediaRateOption {
  CrossSell = 'cross_sell',
  Member = 'member',
  NetRates = 'net_rates',
  None = 'none',
}

/** ExpediaSalesEnvironment is enum for the field expedia_sales_environment */
export enum ExpediaSalesEnvironment {
  HotelOnly = 'hotel_only',
  HotelPackage = 'hotel_package',
  Loyalty = 'loyalty',
}

/** Properties by which Flight connections can be ordered. */
export enum FlightOrderField {
  DepartureTime = 'DEPARTURE_TIME',
  Status = 'STATUS',
}

/** FlightStatus is enum for the field status */
export enum FlightStatus {
  Advertised = 'ADVERTISED',
  Cancelled = 'CANCELLED',
  Departed = 'DEPARTED',
  Diverted = 'DIVERTED',
  Filed = 'FILED',
  Landed = 'LANDED',
  Scheduled = 'SCHEDULED',
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
}

/** Properties by which HotelProperty connections can be ordered. */
export enum HotelPropertyOrderField {
  GuestRating = 'GUEST_RATING',
  MinInclusivePrice = 'MIN_INCLUSIVE_PRICE',
  Name = 'NAME',
  Rank = 'RANK',
  Score = 'SCORE',
  StarRating = 'STAR_RATING',
}

/** HotelPropertySupplySource is enum for the field supply_source */
export enum HotelPropertySupplySource {
  Airbnb = 'Airbnb',
  Booking = 'Booking',
  Expedia = 'Expedia',
  Impala = 'Impala',
  Vrbo = 'Vrbo',
}

export enum HotelTheme {
  AdventureSport = 'ADVENTURE_SPORT',
  AdventureSportProperty = 'ADVENTURE_SPORT_PROPERTY',
  Beach = 'BEACH',
  BeachProperty = 'BEACH_PROPERTY',
  BoutiqueProperty = 'BOUTIQUE_PROPERTY',
  Business = 'BUSINESS',
  BusinessProperty = 'BUSINESS_PROPERTY',
  Casino = 'CASINO',
  CasinoProperty = 'CASINO_PROPERTY',
  City = 'CITY',
  Family = 'FAMILY',
  FamilyProperty = 'FAMILY_PROPERTY',
  Golf = 'GOLF',
  GolfProperty = 'GOLF_PROPERTY',
  GreenSustainableProperty = 'GREEN_SUSTAINABLE_PROPERTY',
  HistoricProperty = 'HISTORIC_PROPERTY',
  HotSprings = 'HOT_SPRINGS',
  HotSpringsProperty = 'HOT_SPRINGS_PROPERTY',
  JapaneseBusinessProperty = 'JAPANESE_BUSINESS_PROPERTY',
  LgbtWelcomingProperty = 'LGBT_WELCOMING_PROPERTY',
  LuxuryProperty = 'LUXURY_PROPERTY',
  Natural = 'NATURAL',
  PetFriendlyProperty = 'PET_FRIENDLY_PROPERTY',
  Romantic = 'ROMANTIC',
  RomanticProperty = 'ROMANTIC_PROPERTY',
  Shopping = 'SHOPPING',
  ShoppingProperty = 'SHOPPING_PROPERTY',
  Ski = 'SKI',
  SkiProperty = 'SKI_PROPERTY',
  Spa = 'SPA',
  SpaProperty = 'SPA_PROPERTY',
  Winery = 'WINERY',
  WineryProperty = 'WINERY_PROPERTY',
}

/** IdentityDocumentDocumentType is enum for the field document_type */
export enum IdentityDocumentDocumentType {
  NationalIdentityCard = 'NATIONAL_IDENTITY_CARD',
  Passport = 'PASSPORT',
}

/** InboundEmailParser is enum for the field parser */
export enum InboundEmailParser {
  AwardWallet = 'AWARD_WALLET',
  None = 'NONE',
}

/** InboundEmailStatus is enum for the field status */
export enum InboundEmailStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Restricted = 'RESTRICTED',
  Review = 'REVIEW',
  Skipped = 'SKIPPED',
  Success = 'SUCCESS',
  Timeout = 'TIMEOUT',
}

/** LocationPOIType is enum for the field poi_type */
export enum LocationPoiType {
  Airport = 'Airport',
  Business = 'Business',
  Hotel = 'Hotel',
  Place = 'Place',
  Restaurant = 'Restaurant',
}

/** LocationType is enum for the field type */
export enum LocationType {
  Address = 'Address',
  Continent = 'Continent',
  Country = 'Country',
  District = 'District',
  Locality = 'Locality',
  Neighborhood = 'Neighborhood',
  Poi = 'POI',
  Place = 'Place',
  Postcode = 'Postcode',
  Region = 'Region',
}

export enum MedicalRequirementType {
  AntigenTest = 'ANTIGEN_TEST',
  LampTest = 'LAMP_TEST',
  MolecularTest = 'MOLECULAR_TEST',
  NucleicAcidTest = 'NUCLEIC_ACID_TEST',
  PcrTest = 'PCR_TEST',
  RtPcrTest = 'RT_PCR_TEST',
  TmaTest = 'TMA_TEST',
  UnspecifiedCovidTest = 'UNSPECIFIED_COVID_TEST',
}

/** NotificationChannel is enum for the field channel */
export enum NotificationChannel {
  Email = 'EMAIL',
  MobilePush = 'MOBILE_PUSH',
  Sms = 'SMS',
  WebPush = 'WEB_PUSH',
}

/** NotificationDeliveryStatus is enum for the field delivery_status */
export enum NotificationDeliveryStatus {
  Blocked = 'BLOCKED',
  Bounced = 'BOUNCED',
  Deferred = 'DEFERRED',
  Delivered = 'DELIVERED',
  Dropped = 'DROPPED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Scheduled = 'SCHEDULED',
}

/** NotificationEngagementStatus is enum for the field engagement_status */
export enum NotificationEngagementStatus {
  Clicked = 'CLICKED',
  Opened = 'OPENED',
  Pending = 'PENDING',
  Spammed = 'SPAMMED',
  Unsubscribed = 'UNSUBSCRIBED',
}

/** NotificationSubscriptionChannel is enum for the field channel */
export enum NotificationSubscriptionChannel {
  Email = 'EMAIL',
  MobilePush = 'MOBILE_PUSH',
  Sms = 'SMS',
  WebPush = 'WEB_PUSH',
}

/** NotificationSubscriptionFrequency is enum for the field frequency */
export enum NotificationSubscriptionFrequency {
  Daily = 'DAILY',
  Instant = 'INSTANT',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

/** Properties by which NotificationSubscription connections can be ordered. */
export enum NotificationSubscriptionOrderField {
  Active = 'ACTIVE',
  Channel = 'CHANNEL',
  Frequency = 'FREQUENCY',
}

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  Asc = 'ASC',
  /** Specifies a descending order for a given `orderBy` argument. */
  Desc = 'DESC',
}

/** Properties by which Page connections can be ordered. */
export enum PageOrderField {
  Rank = 'RANK',
}

/** PageType is enum for the field type */
export enum PageType {
  CityPillarPage = 'CITY_PILLAR_PAGE',
  ContentBlockLandingPage = 'CONTENT_BLOCK_LANDING_PAGE',
  CountryPillarPage = 'COUNTRY_PILLAR_PAGE',
  HotelChainPage = 'HOTEL_CHAIN_PAGE',
  HotelPropertyPage = 'HOTEL_PROPERTY_PAGE',
  PoiLandingPage = 'POI_LANDING_PAGE',
  RegionPillarPage = 'REGION_PILLAR_PAGE',
  Unspecified = 'UNSPECIFIED',
}

export enum PolicyCategory {
  Covid_19Test = 'COVID_19_TEST',
  DepositRequired = 'DEPOSIT_REQUIRED',
  DocRequired = 'DOC_REQUIRED',
  HealthAssessment = 'HEALTH_ASSESSMENT',
  HealthInsurance = 'HEALTH_INSURANCE',
  HealthMeasures = 'HEALTH_MEASURES',
  NoCovid_19Test = 'NO_COVID_19_TEST',
  NoQuarantine = 'NO_QUARANTINE',
  Ppe = 'PPE',
  Quarantine = 'QUARANTINE',
  ReEntryPermit = 'RE_ENTRY_PERMIT',
  Sanitization = 'SANITIZATION',
  TravelInsurance = 'TRAVEL_INSURANCE',
  Vaccination = 'VACCINATION',
}

export enum PolicyDocumentType {
  AccommodationBooking = 'ACCOMMODATION_BOOKING',
  Covid_19Insurance = 'COVID_19_INSURANCE',
  Covid_19Vaccination = 'COVID_19_VACCINATION',
  CovidTestResult = 'COVID_TEST_RESULT',
  EmbassyVisa = 'EMBASSY_VISA',
  Eta = 'ETA',
  ExemptionForm = 'EXEMPTION_FORM',
  EVisa = 'E_VISA',
  HealthDeclaration = 'HEALTH_DECLARATION',
  HealthPass = 'HEALTH_PASS',
  ImmunityProof = 'IMMUNITY_PROOF',
  InDestinationProof = 'IN_DESTINATION_PROOF',
  MedicalCertificate = 'MEDICAL_CERTIFICATE',
  MobileApp = 'MOBILE_APP',
  PassengerLocatorForm = 'PASSENGER_LOCATOR_FORM',
  PreRegistrationForm = 'PRE_REGISTRATION_FORM',
  QuarantineForm = 'QUARANTINE_FORM',
  ReEntryPermit = 'RE_ENTRY_PERMIT',
  TravelAuthorization = 'TRAVEL_AUTHORIZATION',
  TravelDeclaration = 'TRAVEL_DECLARATION',
  Visa = 'VISA',
}

export enum PolicyStage {
  BeforeArrival = 'BEFORE_ARRIVAL',
  BeforeDeparture = 'BEFORE_DEPARTURE',
  Domestic = 'DOMESTIC',
  InAirport = 'IN_AIRPORT',
  InFlight = 'IN_FLIGHT',
  OnArrival = 'ON_ARRIVAL',
}

export enum PreferredSeat {
  CenterAisle = 'CENTER_AISLE',
  CenterMiddle = 'CENTER_MIDDLE',
  CenterWindow = 'CENTER_WINDOW',
  RearAisle = 'REAR_AISLE',
  RearMiddle = 'REAR_MIDDLE',
  RearWindow = 'REAR_WINDOW',
  UpfrontAisle = 'UPFRONT_AISLE',
  UpfrontMiddle = 'UPFRONT_MIDDLE',
  UpfrontWindow = 'UPFRONT_WINDOW',
}

/** PriceItemType is enum for the field type */
export enum PriceItemType {
  Adjustment = 'ADJUSTMENT',
  AirheartServiceFee = 'AIRHEART_SERVICE_FEE',
  AirlineTax = 'AIRLINE_TAX',
  AirportTax = 'AIRPORT_TAX',
  BaseRate = 'BASE_RATE',
  BookingFee = 'BOOKING_FEE',
  CleaningFee = 'CLEANING_FEE',
  Commission = 'COMMISSION',
  Compensation = 'COMPENSATION',
  CreditCardFee = 'CREDIT_CARD_FEE',
  Deposit = 'DEPOSIT',
  Discount = 'DISCOUNT',
  ExtraPersonFee = 'EXTRA_PERSON_FEE',
  FacilityFee = 'FACILITY_FEE',
  Fee = 'FEE',
  LocalTax = 'LOCAL_TAX',
  MandatoryFee = 'MANDATORY_FEE',
  MandatoryTax = 'MANDATORY_TAX',
  PropertyFee = 'PROPERTY_FEE',
  RecoveryChargesAndFees = 'RECOVERY_CHARGES_AND_FEES',
  ResortFee = 'RESORT_FEE',
  SalesTax = 'SALES_TAX',
  ServiceFee = 'SERVICE_FEE',
  Surcharge = 'SURCHARGE',
  Tax = 'TAX',
  TaxAndServiceFee = 'TAX_AND_SERVICE_FEE',
  TourismTax = 'TOURISM_TAX',
  TravelerServiceFee = 'TRAVELER_SERVICE_FEE',
  Unspecified = 'UNSPECIFIED',
  UtilityFee = 'UTILITY_FEE',
}

export enum PromoCodeError {
  AlreadyDiscounted = 'ALREADY_DISCOUNTED',
  ExpiredCode = 'EXPIRED_CODE',
  InvalidCode = 'INVALID_CODE',
  NotValidForTrip = 'NOT_VALID_FOR_TRIP',
  UsageLimitReached = 'USAGE_LIMIT_REACHED',
}

/** PropertyCategory is enum for the field category */
export enum PropertyCategory {
  AgritourismProperty = 'AGRITOURISM_PROPERTY',
  AllInclusiveProperty = 'ALL_INCLUSIVE_PROPERTY',
  Aparthotel = 'APARTHOTEL',
  Apartment = 'APARTMENT',
  BedBreakfast = 'BED_BREAKFAST',
  Cabin = 'CABIN',
  Campsite = 'CAMPSITE',
  Castle = 'CASTLE',
  Chalet = 'CHALET',
  Condo = 'CONDO',
  CondominiumResort = 'CONDOMINIUM_RESORT',
  Cottage = 'COTTAGE',
  CountryHouse = 'COUNTRY_HOUSE',
  Cruise = 'CRUISE',
  Guesthouse = 'GUESTHOUSE',
  HolidayPark = 'HOLIDAY_PARK',
  Hostal = 'HOSTAL',
  HostelBackpackerAccommodation = 'HOSTEL_BACKPACKER_ACCOMMODATION',
  Hotel = 'HOTEL',
  Houseboat = 'HOUSEBOAT',
  Inn = 'INN',
  Lodge = 'LODGE',
  Motel = 'MOTEL',
  Palace = 'PALACE',
  Pension = 'PENSION',
  PousadaBrazil = 'POUSADA_BRAZIL',
  PousadaPortugal = 'POUSADA_PORTUGAL',
  PrivateVacationHome = 'PRIVATE_VACATION_HOME',
  Property = 'PROPERTY',
  Ranch = 'RANCH',
  Residence = 'RESIDENCE',
  Resort = 'RESORT',
  Riad = 'RIAD',
  Ryokan = 'RYOKAN',
  SafariTentalow = 'SAFARI_TENTALOW',
  TownHouse = 'TOWN_HOUSE',
  TreeHouse = 'TREE_HOUSE',
  Villa = 'VILLA',
}

export enum QuarantineAlternativeMeasure {
  HealthCertificate = 'HEALTH_CERTIFICATE',
  ImmunityProof = 'IMMUNITY_PROOF',
  MedicalCertificate = 'MEDICAL_CERTIFICATE',
  MedicalTreatment = 'MEDICAL_TREATMENT',
  Test = 'TEST',
  TestResult = 'TEST_RESULT',
  TestResultNegative = 'TEST_RESULT_NEGATIVE',
}

export enum QuarantineDurationInDays {
  LengthOfStay = 'LENGTH_OF_STAY',
  NoTimePeriod = 'NO_TIME_PERIOD',
  SpecifiedValue = 'SPECIFIED_VALUE',
  UntilTestNegative = 'UNTIL_TEST_NEGATIVE',
  UntilTestResults = 'UNTIL_TEST_RESULTS',
}

export enum QuarantineEnforcementCondition {
  ContactWithCovidCase = 'CONTACT_WITH_COVID_CASE',
  LengthOfStay = 'LENGTH_OF_STAY',
  NonEssentialTravel = 'NON_ESSENTIAL_TRAVEL',
  NoCertificate = 'NO_CERTIFICATE',
  NoTest = 'NO_TEST',
  NoTestResult = 'NO_TEST_RESULT',
  NoTestResultNegative = 'NO_TEST_RESULT_NEGATIVE',
  Symptoms = 'SYMPTOMS',
  TestPositive = 'TEST_POSITIVE',
  TravelHistory = 'TRAVEL_HISTORY',
}

export enum QuarantineMedicalRequirementType {
  AgRdt = 'AG_RDT',
  AntibodyTest = 'ANTIBODY_TEST',
  AntigenFiaRapidTest = 'ANTIGEN_FIA_RAPID_TEST',
  AntigenTest = 'ANTIGEN_TEST',
  LampTest = 'LAMP_TEST',
  MolecularTest = 'MOLECULAR_TEST',
  NaatTest = 'NAAT_TEST',
  NucleicAcidTest = 'NUCLEIC_ACID_TEST',
  PcrTest = 'PCR_TEST',
  RapidTest = 'RAPID_TEST',
  RtLampTest = 'RT_LAMP_TEST',
  RtPcrTest = 'RT_PCR_TEST',
  SerologyTest = 'SEROLOGY_TEST',
  TmaTest = 'TMA_TEST',
  UnspecifiedCovidTest = 'UNSPECIFIED_COVID_TEST',
}

/** Properties by which Region connections can be ordered. */
export enum RegionOrderField {
  Name = 'NAME',
}

/** ReservationGuestGender is enum for the field gender */
export enum ReservationGuestGender {
  Female = 'FEMALE',
  Male = 'MALE',
}

/** ReservationGuestPreferredSeat is enum for the field preferred_seat */
export enum ReservationGuestPreferredSeat {
  CenterAisle = 'CENTER_AISLE',
  CenterMiddle = 'CENTER_MIDDLE',
  CenterWindow = 'CENTER_WINDOW',
  RearAisle = 'REAR_AISLE',
  RearMiddle = 'REAR_MIDDLE',
  RearWindow = 'REAR_WINDOW',
  UpfrontAisle = 'UPFRONT_AISLE',
  UpfrontMiddle = 'UPFRONT_MIDDLE',
  UpfrontWindow = 'UPFRONT_WINDOW',
}

/** ReservationGuestTitle is enum for the field title */
export enum ReservationGuestTitle {
  Dr = 'DR',
  Miss = 'MISS',
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  Unspecified = 'UNSPECIFIED',
}

/** ReservationItemStatus is enum for the field status */
export enum ReservationItemStatus {
  Available = 'AVAILABLE',
  Booked = 'BOOKED',
  Confirmed = 'CONFIRMED',
  CustomerCancel = 'CUSTOMER_CANCEL',
  FraudCancel = 'FRAUD_CANCEL',
  Pending = 'PENDING',
  SupplierCancel = 'SUPPLIER_CANCEL',
  Unavailable = 'UNAVAILABLE',
}

/** ReservationItemType is enum for the field type */
export enum ReservationItemType {
  Airport = 'AIRPORT',
  FlightSlice = 'FLIGHT_SLICE',
  Hotel = 'HOTEL',
  Place = 'PLACE',
}

/** Properties by which ReservationOption connections can be ordered. */
export enum ReservationOptionOrderField {
  FinishesAt = 'FINISHES_AT',
  StartsAt = 'STARTS_AT',
}

/** Properties by which Reservation connections can be ordered. */
export enum ReservationOrderField {
  CancelledAt = 'CANCELLED_AT',
  ConfirmedAt = 'CONFIRMED_AT',
  ExpiredAt = 'EXPIRED_AT',
  PaidAt = 'PAID_AT',
  StartsAt = 'STARTS_AT',
  State = 'STATE',
}

/** ReservationState is enum for the field state */
export enum ReservationState {
  Booking = 'BOOKING',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  ErrorSupportRequired = 'ERROR_SUPPORT_REQUIRED',
  ErrorUserSelectionRequired = 'ERROR_USER_SELECTION_REQUIRED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  RequiresGuests = 'REQUIRES_GUESTS',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
}

/** RoomRateSalesChannel is enum for the field expedia_sales_channel */
export enum RoomRateSalesChannel {
  AgentTool = 'agent_tool',
  Cache = 'cache',
  Meta = 'meta',
  MobileApp = 'mobile_app',
  MobileWeb = 'mobile_web',
  Website = 'website',
}

export enum RoomType {
  EntirePlace = 'EntirePlace',
  HotelRoom = 'HotelRoom',
  PrivateRoom = 'PrivateRoom',
  SharedRoom = 'SharedRoom',
}

/** RouteSummaryEnforcement is enum for the field quarantine_enforcement */
export enum RouteSummaryEnforcement {
  Mandatory = 'MANDATORY',
  MayBeRequired = 'MAY_BE_REQUIRED',
  NotRequired = 'NOT_REQUIRED',
  Optional = 'OPTIONAL',
  Recommended = 'RECOMMENDED',
}

/** Properties by which RouteSummary connections can be ordered. */
export enum RouteSummaryOrderField {
  UpdatedAt = 'UPDATED_AT',
}

export enum SectionElementType {
  Bassinet = 'Bassinet',
  Closet = 'Closet',
  Empty = 'Empty',
  ExitRow = 'ExitRow',
  Galley = 'Galley',
  Lavatory = 'Lavatory',
  Seat = 'Seat',
  Stairs = 'Stairs',
}

/** Properties by which Segment connections can be ordered. */
export enum SegmentOrderField {
  FinishesAt = 'FINISHES_AT',
  StartsAt = 'STARTS_AT',
  Type = 'TYPE',
}

/** SegmentType is enum for the field type */
export enum SegmentType {
  Activity = 'ACTIVITY',
  Cruise = 'CRUISE',
  Event = 'EVENT',
  Flight = 'FLIGHT',
  Lodging = 'LODGING',
  Waypoint = 'WAYPOINT',
}

/** SnapshotOriginalType is enum for the field original_type */
export enum SnapshotOriginalType {
  RouteSummary = 'RouteSummary',
  TravelPolicy = 'TravelPolicy',
  TravelRestriction = 'TravelRestriction',
}

export enum TestWindowConditionType {
  BeforeArrival = 'BEFORE_ARRIVAL',
  BeforeDeparture = 'BEFORE_DEPARTURE',
}

export enum TestWindowType {
  Hours = 'HOURS',
}

/** Properties by which TimelineItem connections can be ordered. */
export enum TimelineItemOrderField {
  Duration = 'DURATION',
  Offset = 'OFFSET',
  Position = 'POSITION',
}

/** TimelineItemType is enum for the field type */
export enum TimelineItemType {
  Airport = 'AIRPORT',
  FlightSlice = 'FLIGHT_SLICE',
  Hotel = 'HOTEL',
  Place = 'PLACE',
}

export enum TravelClass {
  Business = 'Business',
  DiscountEconomy = 'DiscountEconomy',
  Economy = 'Economy',
  First = 'First',
  PremiumEconomy = 'PremiumEconomy',
}

/** TravelPinnedSegmentUserSettingsNotificationChannel is enum for the field notification_channel */
export enum TravelPinnedSegmentUserSettingsNotificationChannel {
  Email = 'EMAIL',
  MobilePush = 'MOBILE_PUSH',
  Sms = 'SMS',
  WebPush = 'WEB_PUSH',
}

/** TravelPinnedSegmentUserSettingsNotificationFrequency is enum for the field notification_frequency */
export enum TravelPinnedSegmentUserSettingsNotificationFrequency {
  Daily = 'DAILY',
  Instant = 'INSTANT',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

/** TravelPolicyCategory is enum for the field category */
export enum TravelPolicyCategory {
  Covid_19Test = 'COVID_19_TEST',
  DepositRequired = 'DEPOSIT_REQUIRED',
  DocRequired = 'DOC_REQUIRED',
  HealthAssessment = 'HEALTH_ASSESSMENT',
  HealthInsurance = 'HEALTH_INSURANCE',
  HealthMeasures = 'HEALTH_MEASURES',
  NoCovid_19Test = 'NO_COVID_19_TEST',
  NoQuarantine = 'NO_QUARANTINE',
  Ppe = 'PPE',
  Quarantine = 'QUARANTINE',
  ReEntryPermit = 'RE_ENTRY_PERMIT',
  Sanitization = 'SANITIZATION',
  TravelInsurance = 'TRAVEL_INSURANCE',
  Vaccination = 'VACCINATION',
}

/** TravelPolicyEnforcement is enum for the field enforcement */
export enum TravelPolicyEnforcement {
  Mandatory = 'MANDATORY',
  MayBeRequired = 'MAY_BE_REQUIRED',
  NotRequired = 'NOT_REQUIRED',
  Optional = 'OPTIONAL',
  Recommended = 'RECOMMENDED',
}

/** Properties by which TravelPolicy connections can be ordered. */
export enum TravelPolicyOrderField {
  Category = 'CATEGORY',
  End = 'END',
  LastChecked = 'LAST_CHECKED',
  Stage = 'STAGE',
  Start = 'START',
  StillCurrentAt = 'STILL_CURRENT_AT',
}

/** TravelPolicyStage is enum for the field stage */
export enum TravelPolicyStage {
  BeforeArrival = 'BEFORE_ARRIVAL',
  BeforeDeparture = 'BEFORE_DEPARTURE',
  Domestic = 'DOMESTIC',
  InAirport = 'IN_AIRPORT',
  InFlight = 'IN_FLIGHT',
  OnArrival = 'ON_ARRIVAL',
}

export enum TravelPurpose {
  Business = 'BUSINESS',
  Medical = 'MEDICAL',
  Tourism = 'TOURISM',
  Transit = 'TRANSIT',
}

/** TravelRestrictionCategory is enum for the field category */
export enum TravelRestrictionCategory {
  Boarding = 'BOARDING',
  Entry = 'ENTRY',
  Exit = 'EXIT',
  InFlight = 'IN_FLIGHT',
  OnArrival = 'ON_ARRIVAL',
  Transit = 'TRANSIT',
}

/** TravelRestrictionDirectionality is enum for the field directionality */
export enum TravelRestrictionDirectionality {
  Less = 'LESS',
  More = 'MORE',
  NoChange = 'NO_CHANGE',
}

/** Properties by which TravelRestriction connections can be ordered. */
export enum TravelRestrictionOrderField {
  End = 'END',
  LastChecked = 'LAST_CHECKED',
  Start = 'START',
  StillCurrentAt = 'STILL_CURRENT_AT',
}

/** TravelRestrictionSubCategory is enum for the field sub_category */
export enum TravelRestrictionSubCategory {
  Boarding = 'BOARDING',
  Entry = 'ENTRY',
  Exit = 'EXIT',
  NoEntry = 'NO_ENTRY',
  NoExit = 'NO_EXIT',
  NoRestriction = 'NO_RESTRICTION',
  RestrictedEntry = 'RESTRICTED_ENTRY',
  RestrictedExit = 'RESTRICTED_EXIT',
  Transit = 'TRANSIT',
}

/** TravelRiskRiskLevel is enum for the field risk_level */
export enum TravelRiskRiskLevel {
  Extreme = 'extreme',
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  Moderate = 'moderate',
  Unknown = 'unknown',
}

export enum TripBookingStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING',
  Planning = 'PLANNING',
}

/** Properties by which Trip connections can be ordered. */
export enum TripOrderField {
  DepartAt = 'DEPART_AT',
  Name = 'NAME',
  PublishedAt = 'PUBLISHED_AT',
  ReturnAt = 'RETURN_AT',
}

export enum TripPaginationFilter {
  Booking = 'Booking',
  Completed = 'Completed',
  Current = 'Current',
  Planning = 'Planning',
  Published = 'Published',
  Upcoming = 'Upcoming',
}

/** TripPublishStatus is enum for the field publish_status */
export enum TripPublishStatus {
  Abandoned = 'ABANDONED',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Review = 'REVIEW',
}

/** UserProfileGender is enum for the field gender */
export enum UserProfileGender {
  Female = 'FEMALE',
  Male = 'MALE',
}

/** UserProfileHonorific is enum for the field title */
export enum UserProfileHonorific {
  Miss = 'MISS',
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
}

/** UserProfilePreferredSeat is enum for the field prefers_seat */
export enum UserProfilePreferredSeat {
  CenterAisle = 'CENTER_AISLE',
  CenterMiddle = 'CENTER_MIDDLE',
  CenterWindow = 'CENTER_WINDOW',
  RearAisle = 'REAR_AISLE',
  RearMiddle = 'REAR_MIDDLE',
  RearWindow = 'REAR_WINDOW',
  UpfrontAisle = 'UPFRONT_AISLE',
  UpfrontMiddle = 'UPFRONT_MIDDLE',
  UpfrontWindow = 'UPFRONT_WINDOW',
}

/** VaccinationStatus is enum for the field vaccination */
export enum VaccinationStatus {
  FullyVaccinated = 'FULLY_VACCINATED',
  NotSpecified = 'NOT_SPECIFIED',
  NotVaccinated = 'NOT_VACCINATED',
}

export enum VaccinationStatusPolicy {
  FullyVaccinated = 'FULLY_VACCINATED',
  NotSpecified = 'NOT_SPECIFIED',
  NotVaccinated = 'NOT_VACCINATED',
}
